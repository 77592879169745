import React, {useState} from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MovieSection from '../components/MovieSection';
import Footer from '../components/footer';
import Services from '../components/Services';
import About from '../components/About';
import ContactBoxForm from '../components/Contact';
import { objOne, objTwo, objThree } from '../components/DataSections/Data';




const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/>
            <MovieSection />
            <About {...objOne}/>
            <Services {...objTwo}/>
            <ContactBoxForm {...objThree}/>
            <Footer />
        </>
    );
};

export default Home;