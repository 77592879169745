export const objOne = {
    id: 'about',
    header: 'O nas',
    description: 'Potrzebujesz pomocy z odzyskaniem utraconych danych z telefonu?' + '\n' +
    'Nasza firma specjalizuje się w odzyskiwaniu danych z różnych modeli telefonów. Bez względu na to, czy masz problem z utraconymi zdjęciami, wiadomościami tekstowymi, kontaktami lub innymi ważnymi plikami, nasz zespół ekspertów jest gotowy, aby pomóc Ci w szybkim i bezpiecznym odzyskiwaniu danych. Korzystamy z najnowocześniejszych narzędzi i technologii, aby zapewnić najwyższą jakość usług. Nasza firma jest znana z szybkiej i skutecznej pracy, a nasi klienci cenią nas za naszą uczciwość i profesjonalizm. Jeśli potrzebujesz pomocy w odzyskaniu danych z telefonu, skontaktuj się z nami już dziś. Jesteśmy tu, aby pomóc!',
    buttonLabel: 'Więcej Tutaj',
    imgStart: false,
    img: require('../../images/about.png').default,
    alt: 'O Nas'
};

export const objTwo = {
    id: 'oferta',
    header: 'Oferujemy usługi odzyskiwania danych z nastepujących urządzeń:',
    description: 'Po latach wznawiamy działalność z nowym zakresem usług jaką jest możliwość odzyskiwania danych z uszkodzonych urządzeń. Na pierwszy ogień będą wykorzystywane telefony komórkowe typu smart, które zostały uszkodzone w bardzo nienaturalny sposób. W dalszej kolejności będziemy zajmować się odzyskiwaniem danych z nośników optycznych, nośników magnetycznych oraz nośników elektronicznych',
    buttonLabel: 'Więcej Tutaj',
    imgStart: false,
    alt: 'Oferta'
};

export const objThree = {
    id: 'contact',
    header: 'Formularz Kontaktowy',
    description: '',
    buttonLabel: 'Wyślij zgłoszenie',
    imgStart: false,
    alt: 'Kontakt',
    width: '100%',
    height: '100%'
};