import React from 'react';
import { SidebarContainer,
        Icon,
        CloseIcon,
        SidebarWrapper,
        SidebarMenu,
        SidebarLink 
} from './SidebarElements';

const Sidebar = ({toggle, isOpen}) => {
  return (
    <>
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='index' onClick={toggle}>Strona Główna</SidebarLink>
                    <SidebarLink to='about' onClick={toggle}>O Nas</SidebarLink>
                    <SidebarLink to='oferta' onClick={toggle}>Oferta</SidebarLink>
                    <SidebarLink to='contact' onClick={toggle}>Kontakt</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    </>
  );
};

export default Sidebar;
