import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/index';
import Rodo from './pages/rodo';
import Rules from './pages/rules';
import PolitykaPrywatnosci from './pages/polityka-prywatnosci';

function App() {
  return (
   <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/rodo" element={< Rodo />} />
      <Route exact path="/rules" element={<Rules />} />
      <Route exact path="/polityka-prywatnosci" element={< PolitykaPrywatnosci />} />
    </Routes>
   </Router>
  );
}

export default App;
