export const articleObjTwo = {
    id: 'rodo',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Obowiązek informacyjny RODO',
    buttonLabel: 'Kliknij aby wrócić',
    imgStart: false,
    alt: 'Obowiązek informacyjny RODO',
    dark: true,
    primary: true,
    darkText: true
};