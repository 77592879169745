export const articleObjThree = {
    id: 'rules',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Regulamin',
    buttonLabel: 'Kliknij aby wrócić',
    imgStart: false,
    alt: 'Regulamin',
    dark: true,
    primary: true,
    darkText: true
};