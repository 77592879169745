import React from 'react';
import { Button2 } from '../../ButtonElements';
import {
    BigFont, BtnWrap, Column1, Heading, Heading2,
    InfoContainer, InfoRow, InfoWrapper, PointLink, RodoContainer, RodoPointList, RodoText, Subtitle, TextWrapper,
    TopLine
} from './DataRodoElements';



const DataRodo = ({ lightBg, id, topLine, lightText, headline, darkText, buttonLabel, primary, dark, dark2 }) => {
    return (
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow >
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <BtnWrap>
                                <Button2 href='/' smooth={true} duration={500} spy={true}
                                    exact='true' offset={-80} primary={primary ? 1 : 0}
                                    dark={dark ? 1 : 0} dark2={dark2 ? 1 : 0}> {buttonLabel} </Button2>
                            </BtnWrap>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}></Subtitle>
                            <RodoContainer darkText={darkText}>
                                <RodoText>Poniższa informacja stanowi zwięzły, zrozumiały i przejrzysty skrót informacji zamieszczonych w <a href="https://ratujemydane.pl/polityka-prywatnosci">Polityce Prywatności</a> odnośnie Administratora danych, celu i sposobu przetwarzania danych osobowych oraz Twoich praw w związku z tym przetwarzaniem, w formie wymaganej do spełnienia obowiązku informacyjnego RODO. Szczegóły dotyczące sposobu przetwarzania i podmiotów uczestniczących w tym procesie dostępne są we wskazanej polityce.</RodoText>
                                <Heading2>Kto jest administratorem danych?</Heading2>
                                <RodoText>Administratorem Danych Osobowych (dalej Administrator) jest firma " Centralne Biuro Odzyskiwania Danych, Marcin Adamczewski ", prowadząca działalność pod adresem: , o nadanym numerze identyfikacji podatkowej (NIP):7772675581, świadcząca usługi drogą elektroniczną za pośrednictwem Serwisu</RodoText>
                                <Heading2>Jak można skontaktować się z administratorem danych?</Heading2>
                                <RodoText>Z Administratorem można skontaktować się w jeden z poniższych sposobów</RodoText>
                                <RodoPointList>
                                    <PointLink>
                                        <RodoText>
                                            <BigFont>Adres pocztowy</BigFont> - 62-025 Kostrzyn, ul. Partyzantów 1/13</RodoText></PointLink>
                                    <PointLink>
                                        <RodoText>
                                            <BigFont>Adres poczty elektronicznej</BigFont> - biuro@ratujemydane.pl</RodoText></PointLink>
                                    <PointLink><RodoText><BigFont>Połączenie telefoniczne</BigFont> - 666349210</RodoText></PointLink>
                                    <PointLink><RodoText><BigFont>Formularz kontaktowy</BigFont> - dostępny pod adresem: /</RodoText></PointLink></RodoPointList>
                                <Heading2>Czy Administrator powołał Inspektora Danych Osobowych?</Heading2>
                                <RodoText>Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora Ochrony Danych.</RodoText><RodoText>W sprawach dotyczących przetwarzania danych, w tym danych osobowych, należy kontaktować się bezpośrednio z Administratorem.</RodoText>
                                <Heading2>Skąd pozyskujemy dane osobowe i jakie są ich źródła?</Heading2>
                                <RodoText>Dane pozyskiwane są z następujących źródeł:</RodoText>
                                <RodoPointList>
                                    <PointLink>od osób, których dane dotyczą</PointLink>
                                    <PointLink>w przypadku rejestracji przy użyciu portali społecznościowych, za wyrażoną świadomą zgodą tych osób, z tych portali społecznościowych</PointLink></RodoPointList>
                                <Heading2>Jaki jest zakres przetwarzanych przez nas danych osobowych?</Heading2>
                                <RodoText>W serwisie przetwarzane są <BigFont>dane osobowe zwykłe</BigFont>, podane dobrowolnie przez osoby, których dotyczą<br /><small>(Np. imię i nazwisko, login, adres e-mail, telefon, adres IP, itp.)</small></RodoText>
                                <RodoText>Szczegółowy zakres przetwarzanych danych dostępny jest w <a href="https://ratujemydane.pl/polityka-prywatnosci">Polityce Prywatności</a>.</RodoText>
                                <Heading2>Jakie są cele przetwarzania przez nas danych?</Heading2>
                                <RodoText>Dane osobowe dobrowolnie podane przez Użytkowników są przetwarzane w jednym z następujących celów:</RodoText>
                                <RodoPointList>
                                    <PointLink>Realizacji usług elektronicznych:
                                        <RodoPointList>
                                            <PointLink>Usługi rejestracji i utrzymania konta Użytkownika w Serwisie i funkcjonalności z nim związanych</PointLink>
                                            <PointLink>Usługi Newslettera (w tym przesyłania za zgodą treści reklamowych)</PointLink>
                                            <PointLink>Usługi komentowania / polubienia wpisów w Serwisie bez konieczności rejestrowania się</PointLink>
                                        </RodoPointList></PointLink>
                                    <PointLink>Komunikacji Administratora z Użytkownikami w sprawach związanych z Serwisem oraz ochrony danych</PointLink>
                                    <PointLink>Zapewnienia prawnie uzasadnionego interesu Administratora</PointLink></RodoPointList>
                                <Heading2>Jakie są podstawy prawne przetwarzania danych?</Heading2>
                                <RodoText>Serwis gromadzi i przetwarza dane Użytkowników na podstawie:</RodoText>
                                <RodoPointList>
                                    <PointLink>Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
                                        <RodoPointList>
                                            <PointLink>art. 6 ust. 1 lit. a<br /><small>osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie określonych celów</small></PointLink>
                                            <PointLink>art. 6 ust. 1 lit. b<br /><small>przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy</small></PointLink>
                                            <PointLink>art. 6 ust. 1 lit. f<br /><small>przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez stronę trzecią</small></PointLink>
                                        </RodoPointList></PointLink>
                                    <PointLink>Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U. 2018 poz. 1000)</PointLink><PointLink>Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171 poz. 1800)</PointLink><PointLink>Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. 1994 Nr 24 poz. 83)</PointLink></RodoPointList>
                                <Heading2>Jaki jest prawnie uzasadniony interes realizowany przez Administratora?</Heading2>
                                <RodoPointList>
                                    <PointLink>W celu ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami – podstawą prawną przetwarzania jest nasz uzasadniony interes (art. 6 ust. 1 lit. f) RODO) polegający na ochronie naszych praw, w tym między innymi;</PointLink>
                                    <PointLink>W celu oceny ryzyka potencjalnych klientów</PointLink>
                                    <PointLink>W celu oceny planowanych kampanii marketingowych</PointLink>
                                    <PointLink>W celu realizacji marketingu bezpośredniego</PointLink>
                                </RodoPointList>
                                <Heading2>Przez jaki okres przetwarzamy dane osobowe?</Heading2>
                                <RodoText>Co do zasady, wskazane dane osobowe są przechowywane wyłącznie przez okres świadczenia usługi w ramach prowadzonego serwisu przez Administratora. Są one usuwane lub anonimizowane w okresie do<BigFont>30 dni od chwili zakończenia świadczenia usług</BigFont> (np. usunięcie zarejestrowanego konta użytkownika, wypisanie z listy Newsletter, itp.)</RodoText>
                                <RodoText>W wyjątkowych sytuacjach, w celu zabezpieczenie prawnie uzasadnionego interesu realizowanego przez Administratora, okres ten może ulec wydłużeniu. W takiej sytuacji Administrator będzie przechowywał wskazane dane, od czasu żądania ich usunięcia przez Użytkownika, nie dłużej niż przez okres 3 lat w przypadku naruszenia lub podejrzenia naruszenia zapisów regulaminu serwisu przez osobę, której dane dotyczą.</RodoText>
                                <Heading2>Kto jest odbiorcą danych w tym danych osobowych?</Heading2>
                                <RodoText>Co do zasady jedynym odbiorcą danych jest Administrator.</RodoText>
                                <RodoText>Przetwarzanie danych może jednak być powierzone innym podmiotom, realizującym usługi na rzecz Administratora w celu utrzymania działalności Serwisu.</RodoText>Do podmiotów takich można zaliczyć między innymi:
                                <RodoPointList>
                                    <PointLink>Firmy hostingowe, świadczące usługi hostingu lub usług pokrewnych dla Administratora</PointLink><PointLink>Firmy, za pośrednictwem których świadczona jest usługa Newslettera</PointLink>
                                    <PointLink>Firmy serwisowe i wsparcia IT dokonujące konserwacji lub odpowiedzialne za utrzymanie infrastruktury IT</PointLink>
                                    <PointLink>Firmy pośredniczące w płatnościach on-line za towaru lub usługi oferowane w ramach Serwisu (w przypadku dokonywania transakcji zakupu w Serwisie)</PointLink>
                                    <PointLink>Firmy pośredniczące w płatnościach mobilnych za towaru lub usługi oferowane w ramach Serwisu (w przypadku dokonywania transakcji zakupu w Serwisie)</PointLink>
                                    <PointLink>Firmy odpowiedzialne za dostarczanie produktów fizycznych do Użytkownika (usługi pocztowe / kurierskie w przypadku dokonywania transakcji zakupu w Serwisie)</PointLink>
                                </RodoPointList>
                                <Heading2>Czy Państwa dane osobowe będą przekazywane poza Unię Europejską?</Heading2>
                                <RodoText>Dane osobowe <BigFont>nie będą przekazywane poza Unię Europejską</BigFont>, chyba że zostały opublikowane na skutek indywidualnego działania Użytkownika (np. wprowadzenie komentarza lub wpisu), co sprawi, że dane będą dostępne dla każdej osoby odwiedzającej serwis.</RodoText>
                                <Heading2>Czy dane osobowe będą podstawą zautomatyzowanego podejmowania decyzji?</Heading2>
                                <RodoText>Dane osobowe <BigFont>nie będą wykorzystywane</BigFont> do zautomatyzowanego podejmowania decyzji (profilowania).</RodoText>
                                <Heading2>Jakie mają Państwo prawa związane z przetwarzaniem danych osobowych?</Heading2>
                                <RodoPointList>
                                    <PointLink>
                                        <RodoText><BigFont>Prawo dostępu do danych osobowych</BigFont><br />Użytkownikom przysługuje prawo uzyskania dostępu do swoich danych osobowych, realizowane na żądanie złożone do Administratora</RodoText>
                                    </PointLink>
                                    <PointLink>
                                        <RodoText><BigFont>Prawo do sprostowania danych osobowych</BigFont><br />Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego sprostowania danych osobowych, które są nieprawidłowe lub / oraz uzupełnienia niekompletnych danych osobowych, realizowane na żądanie złożone do Administratora</RodoText>
                                    </PointLink>
                                    <PointLink>
                                        <RodoText><BigFont>Prawo do usunięcia danych osobowych</BigFont><br />Użytkownikom przysługuje prawo żądania od Administratora niezwłocznego usunięcia danych osobowych, realizowane na żądanie złożone do Administratora. W przypadku kont użytkowników, usunięcie danych polega na anonimizacji danych umożliwiających identyfikację Użytkownika.W przypadku usługi Newsletter, Użytkownik ma możliwość samodzielnego usunięcia swoich danych osobowych korzystając z odnośnika umieszczonego w każdej przesyłanej wiadomości e-mail.</RodoText>
                                    </PointLink>
                                    <PointLink>
                                        <RodoText><BigFont>Prawo do ograniczenia przetwarzania danych osobowych</BigFont><br />Użytkownikom przysługuje prawo ograniczenia przetwarzania danych osobowych w przypadkach wskazanych w art. 18 RODO, m.in. kwestionowania prawidłowość danych osobowych, realizowane na żądanie złożone do Administratora</RodoText>
                                    </PointLink><
                                        PointLink><RodoText><BigFont>Prawo do przenoszenia danych osobowych</BigFont><br />Użytkownikom przysługuje prawo uzyskania od Administratora, danych osobowych dotyczących Użytkownika w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego, realizowane na żądanie złożone do Administratora</RodoText>
                                    </PointLink>
                                    <PointLink>
                                        <RodoText><BigFont>Prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych</BigFont><br />Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec przetwarzania jego danych osobowych w przypadkach określonych w art. 21 RODO, realizowane na żądanie złożone do Administratora</RodoText>
                                    </PointLink>
                                    <PointLink>
                                        <RodoText><BigFont>Prawo wniesienia skargi</BigFont><br />Użytkownikom przysługuje prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych.</RodoText>
                                    </PointLink>
                                </RodoPointList>
                            </RodoContainer>
                        </TextWrapper>
                    </Column1>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer >

    )
};

export default DataRodo;