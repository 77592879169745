import styled from 'styled-components';
import {MdArrowForward, MdKeyboardArrowRight} from 'react-icons/md';

export const ContactContainer = styled.div`
    background: white;
    display: flex;
    padding: 0 35px;
    height: 450px;
    position: relative;
    z-index: 2;
    border-radius: 5px;
    align-content: center;

    @media screen and (max-width:768px){
        height: 100%;
    }
    @media screen and (max-width:480px){
        height: 100%;
    }
`;


export const ContactH1 = styled.h1`
    color: #000;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    padding: 25px;

    @media screen and (max-width:768px){
        font-size: 22px;
    }
    @media screen and (max-width:480px){
        font-size: 18px;
    }
`;


export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: right;
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 768px) {
       grid-template-areas: ${({ imgStart }) => imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 10px;
    padding: 10px 0;
    grid-area: col1;
    @media screen and (max-width:768px){
        margin-bottom: -20px;
    }
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
    @media screen and (max-width:768px){
        
    }
`;

export const Img = styled.img`
    padding-right: 0;
    background: #fff;
    width: 70%;
    height: 70%;
    align-content: center;
    @media screen and (max-width:768px){
        width: 100%;
    }
`;

export const ContactBox = styled.form`
    box-sizing: border-box;
    `;

export const NameBox = styled.input`
    width: 48%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin: 7px 10px 7px auto;
    @media screen and (max-width:768px){
        width: 100%;
    }
`;

export const EmailBox = styled.input`
    width: 48%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin: 7px 10px 7px auto;
    @media screen and (max-width:768px){
        width: 100%;
    }
`;

export const PhoneBox = styled.input`
    width: 48%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin: 7px 10px 7px auto;
    @media screen and (max-width:768px){
        width: 100%;
    }
`;

export const DeviceTypeBox = styled.select`
    width: 48%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    margin: 7px 10px 7px auto;
    @media screen and (max-width:768px){
        width: 100%;
    }
`;

export const ResizeBox = styled.input`
    width: 97%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    height: 120px;
    margin: 7px auto 20px auto;
    justify-content: normal;
    @media screen and (max-width:768px){
        width: 100%;
    }
`;


export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;    
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;    
`;
