import React from "react";
import {
  RulesContainer,
  RulesText,
  Heading2,
  InfoContainer,
  RulesPointList,
  PointLink,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
} from "./DataRulesElements";
import { Button2 } from "../../ButtonElements";

const DataRules = ({
  lightBg,
  id,
  topLine,
  lightText,
  headline,
  darkText,
  buttonLabel,
  primary,
  dark,
  dark2,
}) => {
  return (
    <InfoContainer lightBg={lightBg} id={id}>
      <InfoWrapper>
        <InfoRow>
          <Column1>
            <TextWrapper>
              <TopLine>{topLine}</TopLine>
              <BtnWrap>
                <Button2
                  href="/"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                >
                  {" "}
                  {buttonLabel}{" "}
                </Button2>
              </BtnWrap>
              <Heading lightText={lightText}>{headline}</Heading>
              <Subtitle darkText={darkText}></Subtitle>
              <RulesContainer darkText={darkText}>
                <Heading2>I. Pojęcia ogólne</Heading2>
                <RulesPointList>
                  <PointLink>
                    <strong>Regulamin</strong> – niniejszy regulamin
                  </PointLink>
                  <PointLink>
                    <strong>Serwis</strong> – serwis internetowych
                    &quot;ratujemydane.pl&quot;, działających pod adresem
                    https:/ratujemydane.pl
                  </PointLink>
                  <PointLink>
                    <strong>Usługodawca</strong> – firma Centralne Biuro Odzyskiwania Danych, Marcin Adamczewski z adresem siedziby: 62-025 Kostrzyn, ul. Partyzantów 1/13, NIP: 7772675581
                    ,{" "}
                  </PointLink>
                  <PointLink>
                    <strong>Usługobiorca</strong> – każda osoba fizyczna,
                    uzyskująca dostęp do Serwisu i korzystająca z usług
                    świadczonych za pośrednictwem Serwisu przez Usługodawcę.
                  </PointLink>
                  <PointLink>
                    <strong>Komunikacja Drogą Elektroniczną</strong> – biuro@ratujemydane.pl, 
                    Komunikacja pomiędzy stronami za pośrednictwem poczty
                    elektronicznej (e-mail) oraz formularzy kontaktowych
                    dostępnych na stronie www.
                  </PointLink>
                </RulesPointList>
                <Heading2>II. Postanowienia ogólne</Heading2>
                <RulesPointList>
                  <PointLink>
                    Regulamin, określa zasady funkcjonowania i użytkowania
                    Serwisu oraz określa zakres praw i obowiązków Usługobiorców
                    i Usługodawcy związanych z użytkowaniem Serwisu.
                  </PointLink>
                  <PointLink>
                    Przedmiotem usług Usługodawcy jest udostępnienie
                    nieodpłatnych narzędzi w postaci Serwisu, umożliwiających
                    Usługobiorcom dostęp do treści w postaci wpisów, artykułów i
                    materiałów audiowizualnych lub aplikacji internetowych i
                    formularzy elektronicznych
                  </PointLink>
                  <PointLink>
                    Wszelkie ewentualne treści, artykuły i informacje
                    zawierające cechy wskazówek lub porad publikowane na łamach
                    Serwisu są jedynie ogólnym zbiorem informacji i nie są
                    kierowane do poszczególnych Usługobiorców. Usługodawca nie
                    ponosi odpowiedzialności za wykorzystanie ich przez
                    Usługobiorców.
                  </PointLink>
                  <PointLink>
                    Usługobiorca bierze na siebie pełną odpowiedzialno za sposób
                    wykorzystania materiałów udostępnianych w ramach Serwisu w
                    tym za wykorzystanie ich zgodnie z obowiązującymi przepisami
                    prawa.
                  </PointLink>
                  <PointLink>
                    Usługodawca nie udziela żadnej gwarancji co do przydatności
                    materiałów umieszczonych w Serwisie.
                  </PointLink>
                  <PointLink>
                    Usługodawca nie ponosi odpowiedzialności z tytułu
                    ewentualnych szkód poniesionych przez Usługobiorców Serwisu
                    lub osoby trzecie w związku z korzystaniem z Serwisu.
                    Wszelkie ryzyko związane z korzystaniem z Serwisu, a w
                    szczególności z używaniem i wykorzystywaniem informacji
                    umieszczonych w Serwisie, ponosi Usługobiorca korzystający z
                    usług Serwisu.
                  </PointLink>
                </RulesPointList>
                <Heading2>III. Warunki używania Serwisu</Heading2>
                <RulesPointList>
                  <PointLink>
                    Używanie Serwisu przez każdego z Usługobiorców jest
                    nieodpłatne i dobrowolne.
                  </PointLink>
                  <PointLink>
                    Usługobiorcy mają obowiązek zapoznania się z Regulaminem
                    oraz pozostałymi dokumentami stanowiącymi jego integralną
                    część i muszą zaakceptować w całości jego postanowienia w
                    celu dalszego korzystania z Serwisu.
                  </PointLink>
                  <PointLink>
                    Usługobiorcy nie mogą wykorzystywać żadnych pozyskanych w
                    Serwisie danych osobowych do celów marketingowych.
                  </PointLink>{" "}
                  <PointLink>
                    Wymagania techniczne korzystania z Serwisu:
                    <RulesPointList>
                      <PointLink>
                        urządzenie z wyświetlaczem umożliwiające wyświetlanie
                        stron internetowych,
                      </PointLink>
                      <PointLink>połączenie z internetem,</PointLink>
                      <PointLink>
                        dowolna przeglądarka internetowa, która wyświetla strony
                        internetowe zgodnie ze standardami i postanowieniami
                        Konsorcjum W3C i obsługuje strony www udostępniane w
                        języku HTML5,
                      </PointLink>
                      <PointLink>
                        włączoną obsługę skryptów JavaScript,
                      </PointLink>
                      <PointLink>włączoną obsługę plików Cookie</PointLink>
                    </RulesPointList>
                  </PointLink>
                  <PointLink>
                    W celu zapewnienia bezpieczeństwa Usługodawcy, Usługobiorcy
                    oraz innych Usługobiorców korzystających z Serwisu, wszyscy
                    Usługobiorcy korzystający z Serwisu powinni stosować się do
                    ogólnie przyjętych{" "}
                    <a href="https://nety.pl/cyberbezpieczenstwo/zasady-ogolne-korzystania-z-sieci-internet/">
                      zasad bezpieczeństwa w sieci
                    </a>
                    ,
                  </PointLink>{" "}
                  <PointLink>
                    Zabrania się działań wykonywanych osobiście przez
                    Usługobiorców lub przy użyciu oprorgamowania:
                    <RulesPointList>
                      <PointLink>
                        bez zgody pisemnej, dekompilacji i analizy kodu
                        źródłowego,
                      </PointLink>
                      <PointLink>
                        bez zgody pisemnej, powodujących nadmierne obciążenie
                        serwera Serwisu,
                      </PointLink>
                      <PointLink>
                        bez zgody pisemnej, prób wykrycia luk w zabezpieczeniach
                        Serwisu i konfiguracji serwera,
                      </PointLink>
                      <PointLink>
                        podejmowania prób wgrywania lub wszczykiwania na serwer
                        i do bazy danych kodu, skryptów i oprogramowania
                        mogących wyrządzić szkodę oprogramowaniu Serwisu, innym
                        Usługobiorcom lub Usługodawcy,
                      </PointLink>
                      <PointLink>
                        podejmowania prób wgrywania lub wszczykiwania na serwer
                        i do bazy danych kodu, skryptów i oprogramowania
                        mogących śledzić lub wykradać dane Usługobiorców lub
                        Usługodawcy,
                      </PointLink>
                      <PointLink>
                        podejmowania jakichkolwiek działań mających na celu
                        uszkodzenie, zablokowanie działania Serwisu lub
                        uniemożliwienie realizacji celu w jakim działa Serwis.
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                  <PointLink>
                    W przypadku wykrycia zaistnienia lub potencjalnej możliwości
                    zaistnienia incydentu Cyberbezpieczeństwa lub naruszenia
                    RODO, Usługobiorcy w pierwszej kolejności powinni zgłosić
                    ten fakt Usługodawcy w celu szybkiego usunięcia problemu /
                    zagrożenia i zabezpieczenia interesów wszystkich
                    Usługobiorców Serwisu.
                  </PointLink>
                </RulesPointList>
                <Heading2>IV. Warunki oraz zasady rejestracji</Heading2>
                <RulesPointList>
                  <PointLink>
                    Usługobiorcy mogą korzystać z Serwisu bez konieczności
                    rejestracji.
                  </PointLink>
                  <PointLink>
                    Usługobiorcy muszą być zarejestrowani i posiadać konto w
                    Serwisie by korzystać z dodatkowych usług świadczonych w
                    Serwisie, dostępnych jedynie dla Usługobiorców po
                    zalogowaniu.
                  </PointLink>
                  <PointLink>Rejestracja w Serwisie jest dobrowolna.</PointLink>
                  <PointLink>
                    Rejestracja w Serwisie jest nieodpłatna.
                  </PointLink>
                  <PointLink>
                    Każdy Usługobiorca może posiadać tylko jedno konto w
                    Serwisie.
                  </PointLink>{" "}
                  <PointLink>
                    Wymagania techniczne związane z rejestracją konta:
                    <RulesPointList>
                      <PointLink>
                        posiadanie indywidualnego konta poczty elektronicznej
                        e-mail,
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                  <PointLink>
                    Rejestrujący się w Serwisie Usługobiorcy wyrażają zgodę na
                    przetwarzanie ich danych osobowych przez Usługobiorcę w
                    zakresie w jakim zostały one wprowadzone do Serwisu podczas
                    procesu rejestracji oraz ich późniejszych zmianom lub
                    usunięciu.
                  </PointLink>
                  <PointLink>
                    Usługodawca ma prawo zawieszać lub usuwać konta
                    Usługobiorców według własnego uznania, uniemożliwiając lub
                    ograniczając w ten sposób dostęp do poszczególnych lub
                    wszystkich usług, treści, materiałów i zasobów Serwisu, w
                    szczególności jeżeli Usługobiorca dopuści się łamania
                    Regulaminu, powszechnie obowiązujących przepisów prawa,
                    zasad współżycia społecznego lub działa na szkodę
                    Usługodawcy lub innych Usługobiorców, uzasadnionego interesu
                    Usługodawcy oraz podmiotów trzecich współpracujących lub nie
                    z Usługodawcą.
                  </PointLink>
                  <PointLink>
                    Wszelkie usługi Serwisu mogą być zmieniane co do ich treści
                    i zakresu, dodawane lub odejmowane, a także czasowo
                    zawieszane lub dostęp do nich może być ograniczany, według
                    swobodnej decyzji Usługodawcy, bez możliwości wnoszenia
                    sprzeciwu w tym zakresie przez Usługobiorców.
                  </PointLink>
                  <PointLink>
                    Dodatkowe zasady bezpieczeństwa w zakresie korzystania z
                    konta:
                    <RulesPointList>
                      <PointLink>
                        Zabrania się Usługobiorcom zarejestrowanym w Serwisie do
                        udostępniania loginu oraz hasła do swojego konta osobom
                        trzecim.
                      </PointLink>
                      <PointLink>
                        Usługodawca nie ma prawa i nigdy nie będzie zażądać od
                        Usługobiorcy hasła do wybranego konta.
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                  <PointLink>
                    Usuwanie konta:
                    <RulesPointList>
                      <PointLink>
                        Każdy Usługobiorca posiadający konto w Serwisie ma
                        możliwość samodzielnego usunięcia konta z Serwisu.
                      </PointLink>
                      <PointLink>
                        Usługobiorcy mogą to uczynić po zalogowaniu się w panelu
                        w Serwisie.
                      </PointLink>
                      <PointLink>
                        Usunięcie konta skutkuje usunięciem wszelkich danych
                        identyfikacyjnych Usługobiorcy oraz anonimizacją nazwy
                        użytkownika i adresu e-mail.
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                </RulesPointList>
                <Heading2>V. Warunki świadczenia usługi Newsletter</Heading2>
                <RulesPointList>
                  <PointLink>
                    Usługobiorcy mogą korzystać z Serwisu bez konieczności
                    zapisywania się do Newslettera.
                  </PointLink>
                  <PointLink>
                    Zapisanie się usługi Newslettera jest dobrowolne.
                  </PointLink>
                  <PointLink>
                    Zapisanie się do usługi Newslettera jest nieodpłatne.
                  </PointLink>{" "}
                  <PointLink>
                    Wymagania techniczne związane z usługą Newsletter:
                    <RulesPointList>
                      <PointLink>
                        posiadanie indywidualnego konta poczty elektronicznej
                        e-mail,
                      </PointLink>
                    </RulesPointList>
                  </PointLink>{" "}
                  <PointLink>
                    Warunki świadczenia usługi Newsletter:
                    <RulesPointList>
                      <PointLink>
                        podanie w formularzu elektronicznym indywidualnego konta
                        poczty elektronicznej e-mail,
                      </PointLink>
                      <PointLink>
                        weryfikacja podanego konta pocztowego e-mail poprzez
                        uruchomienie przesłanego na nie odnośnika,
                      </PointLink>
                      <PointLink>
                        wyrażenie zgody na otrzymywania powiadomień e-mail,
                      </PointLink>
                    </RulesPointList>
                  </PointLink>{" "}
                  <PointLink>
                    Zakres usługi Newsletter:
                    <RulesPointList>
                      <PointLink>
                        powiadamianie o nowych aktualnościach, wpisach,
                        konkursach, i innych akcjach promocyjnych związanych z
                        usługami Serwisu,
                      </PointLink>
                      <PointLink>
                        powiadamianie o akcjach promocyjnych partnerów Serwisu
                        (wiadomości Marketingowe),
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                  <PointLink>
                    Wypisanie się z usługi Newsletter:
                    <RulesPointList>
                      <PointLink>
                        Każdy Usługobiorca zapisany do usługi Newsletter ma
                        możliwość samodzielnego wypisania się z Usługi.
                      </PointLink>
                      <PointLink>
                        Usługobiorcy mogą to uczynić poprzez link umieszczony w
                        każdej przesłanej wiadomości e-mail.
                      </PointLink>
                      <PointLink>
                        Wypisanie się z usługi Newsletter skutkuje usunięciem
                        podanego adresu e-mail z bazy Usługodawcy.
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                </RulesPointList>
                <Heading2>
                  VI. Warunki komunikacji i świadczenia pozostałych usług w
                  Serwisie
                </Heading2>
                <RulesPointList>
                  <PointLink>
                    Serwis udostępnia usługi i narzędzia umożliwiające
                    Usługobiorcom interakcję z Serwisem w postaci:
                    <RulesPointList>
                      <PointLink>Formularz kontaktowy</PointLink>
                      <PointLink>Komentowania wpisów i artykułów</PointLink>
                      <PointLink>
                        Publikowania własnych treści w postaci wpisów i
                        artykułów
                      </PointLink>
                      <PointLink>
                        Publikowanie własnych treści w postaci materiałów
                        graficznych i multimedialnych
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                  <PointLink>
                    Serwis udostępnia dane kontaktowe w postaci:
                    <RulesPointList>
                      <PointLink>Adresu e-mail</PointLink>
                      <PointLink>Telefonu kontaktowego</PointLink>
                    </RulesPointList>
                  </PointLink>
                  <PointLink>
                    W przypadku kontaktu Usługobiorcy z Usługodawcą, dane
                    osobowe Usługobiorców będa przetwarzane zgodnie z &quot;
                    <a href="https://ratujemydane.pl/polityka-prywatnosci">
                      Polityką Prywatności
                    </a>
                    &quot;, stanowiącą integralną część Regulaminu.
                  </PointLink>
                  <PointLink>
                    Warunki umieszczania treści przez Usługobiorców w Serwisie:
                    <RulesPointList>
                      <PointLink>
                        Zabrania się umieszczania w Serwisie treści obraźliwych
                        lub oszczerczych względem Usługodawcy, pozostałych
                        Usługobiorców, osób trzecich oraz podmiotów trzecich,
                      </PointLink>
                      <PointLink>
                        Zabrania się umieszczania w Serwisie materiałów
                        tekstowcyh, graficznych, audiowizualnych, skryptów,
                        programów i innych utworów, na które Usługobiorca nie
                        posiada się licencji, lub których autor praw majątkowych
                        nie wyraził zgody na darmową publikację,
                      </PointLink>
                      <PointLink>
                        Zabrania się umieszczania w Serwisie treści wulgarnych,
                        pornograficznych, erotycznych i niezgodnych z polskim i
                        europejskim prawem a także odnośników do stron
                        zawierających wskazane treści,
                      </PointLink>
                      <PointLink>
                        Zabrania się umieszczania w Serwisie skryptów i
                        programów nadmiernie obciążających serwer,
                        oprogramowania nielegalnego, oprogramowania służącego do
                        naruszania zabezpieczeń oraz innych podobnych działań a
                        także odnośników do stron zawierających wskazane
                        materiały,
                      </PointLink>
                      <PointLink>
                        Zabrania się umieszczania w Serwisie treści
                        merketingowych i reklamujących inne serwisy komercyjne,
                        produkty, usługi czy komercyjne strony internetowe
                      </PointLink>
                    </RulesPointList>
                  </PointLink>
                </RulesPointList>
                <Heading2>VII. Gromadzenie danych o Usługobiorcach</Heading2>
                <RulesText>
                  W celu prawidłowego świadczenia usług przez Serwis,
                  zabezpieczenia prawnego interesu Usługodawcy oraz w celu
                  zapewnienia zgodności działania Serwisu z obowiązującym
                  prawem, Usługodawca za pośrednictwem Serwisu gromadzi i
                  przetwarza niektóre dane o Użytkownikach.
                </RulesText>
                <RulesText>
                  W celu prawidłowego świadczenia usług, Serwis wykorzystuje i
                  zapisuje niektóre anonimowe informacje o Usługobiorcy w
                  plikach cookies.
                </RulesText>
                <RulesText>
                  Zakres, cele, sposób oraz zasady przetwarzania danych dostępne
                  są w załącznikach do Regulaminu: &#8222;
                  <a href="https://ratujemydane.pl/rodo">
                    Obowiązek informacyjny RODO
                  </a>
                  &#8221; oraz w &#8222;
                  <a href="https://ratujemydane.pl/polityka-prywatnosci">
                    Polityce prywatności
                  </a>
                  &#8222;, stanowiących integralną część Regulaminu.
                </RulesText>
                <RulesPointList>
                  <PointLink>
                    <em>Dane zbierane automatycznie:</em>
                    <br />
                    Do sprawnego działania Serwisu oraz do statystyk zbieramy
                    automatycznie niektóre dane o Usługobiorcy. Do danych tych
                    należą:
                    <RulesPointList>
                      <PointLink>Adres IP</PointLink>
                      <PointLink>Typ przeglądarki</PointLink>
                      <PointLink>Rozdzielczość ekranu</PointLink>
                      <PointLink>Przybliżona lokalizacja</PointLink>
                      <PointLink>Otwierane podstrony serwisu</PointLink>
                      <PointLink>
                        Czas spędzony na odpowiedniej podstronie serwisu
                      </PointLink>
                      <PointLink>Rodzaj systemu operacyjnego</PointLink>
                      <PointLink>Adres poprzedniej podstrony</PointLink>
                      <PointLink>Adres strony odsyłającej</PointLink>
                      <PointLink>Język przeglądarki</PointLink>
                      <PointLink>Predkość łącza internetowego</PointLink>
                      <PointLink>Dostawca usług internetowych</PointLink>
                      <PointLink>
                        Anonimowe dane demograficzne na podstawie danych Google
                        Analytics:{" "}
                        <RulesPointList>
                          <PointLink>Płeć</PointLink>
                          <PointLink>Wiek</PointLink>
                          <PointLink>Zainteresowania</PointLink>
                        </RulesPointList>
                      </PointLink>
                    </RulesPointList>
                    <RulesText>
                      Powyższe dane uzyskiwane są poprzez skrypt Google
                      Analytics i są anonimowe.
                    </RulesText>
                  </PointLink>
                  <PointLink>
                    <em>Dane zbierane podczas rejestracji:</em>
                    <br />
                    Nazwa użytkownika, imię i nazwisko, adres e-mail
                    <RulesText>
                      W przypadku Usługobiorców zalogowanych (posiadających
                      konto w Serwisie), w plikach cookies zapisywanych na
                      urządzeniu Usługobiorcy może być umieszczony identyfikator
                      Usługobiorcy powiązany z kontem Usługobiorcy
                    </RulesText>
                  </PointLink>
                  <PointLink>
                    <em>Dane zbierane podczas zapisywania do newslettera:</em>
                    <br />
                    adres e-mail
                  </PointLink>
                </RulesPointList>
                <Heading2>VIII. Prawa autorskie</Heading2>
                <RulesPointList>
                  <PointLink>
                    Właścicielem Serwisu oraz praw autorskich do serwisu jest
                    Usługodawca.
                  </PointLink>
                  <PointLink>
                    Część danych zamieszczonych w Serwisie są chronione prawami
                    autorskimi należącymi do firm, instytucji i osób trzecich,
                    niepowiązanych w jakikolwiek sposób z Usługodawcą, i są
                    wykorzystywane na podstawie uzyskanych licencji, lub
                    opartych na licencji darmowej.
                  </PointLink>
                  <PointLink>
                    Na podstawie Ustawy z dnia 4 lutego 1994 o prawie autorskim
                    zabrania się wykorzystywania, kopiowania, reprodukowania w
                    jakiejkolwiek formie oraz przetrzymywania w systemach
                    wyszukiwania z wyłączeniem wyszukiwarki Google, Bing, Yahoo,
                    NetSprint, DuckDuckGo, Facebook oraz LinkedIn jakichkolwiek
                    artykułów, opisów, zdjęć oraz wszelkich innych treści,
                    materiałów graficznych, wideo lub audio znajdujących się w
                    Serwisie bez pisemnej zgody lub zgody przekazanej za pomocą
                    Komunikacji Drogą Elektroniczną ich prawnego właściciela.
                  </PointLink>
                  <PointLink>
                    Zgodnie z Ustawą z dnia 4 lutego 1994 o prawie autorskim
                    ochronie nie podlegają proste informacje prasowe, rozumiane
                    jako same informacje, bez komentarza i oceny ich autora.
                    Autor rozumie to jako możliwość wykorzystywania informacji z
                    zamieszczonych w serwisie tekstów, ale już nie kopiowania
                    całości lub części artykułów o ile nie zostało to oznaczone
                    w poszczególnym materiale udostępnionym w Serwisie.
                  </PointLink>
                </RulesPointList>
                <Heading2>IX. Zmiany Regulaminu</Heading2>
                <RulesPointList>
                  <PointLink>
                    Wszelkie postanowienia Regulaminu mogą być w każdej chwili
                    jednostronnie zmieniane przez Usługodawcę, bez podawania
                    przyczyn.
                  </PointLink>
                  <PointLink>
                    Informacja o zmianie Regulaminu będzie rozsyłana Drogą
                    Elektroniczną do Usługobiorców zarejestrowanych w Serwisie.
                  </PointLink>
                  <PointLink>
                    W przypadku zmiany Regulaminu jego postanowienia wchodzą w
                    życie natychmiast po jego publikacji dla Usługobiorców
                    nieposiadających konta w Serwisie.
                  </PointLink>
                  <PointLink>
                    W przypadku zmiany Regulaminu jego postanowienia wchodzą w
                    życie z 7-dniowym okresem przejściowym dla Usługobiorców
                    posiadających konta w Serwisie zarejestrowane przez zmianą
                    Regulaminu.
                  </PointLink>
                  <PointLink>
                    Traktuje się iż każdy Usługobiorca, kontynuujący korzystanie
                    z Serwisu po zmianie Regulaminu akceptuje go w całości.
                  </PointLink>
                </RulesPointList>
                <Heading2>X. Postanowienia końcowe</Heading2>
                <RulesPointList>
                  <PointLink>
                    Usługodawca nie odpowiada w żaden sposób, jak tylko
                    pozwalają na to obowiązujące przepisy prawa, za treści
                    przekazywane i publikowane w Serwisie przez Usługobiorców,
                    za ich prawdziwość, rzetelność, autentyczność czy wady
                    prawne.
                  </PointLink>
                  <PointLink>
                    Usługodawca dokona wszelkich starań by usługi Serwisu były
                    oferowane w sposób ciągły. Nie ponosi on jednak żadnej
                    odpowiedzialności za zakłócenia spowodowane siłą wyższą lub
                    niedozwoloną ingerencją Usługobiorców, osób trzecich czy
                    działalnością zewnętrznych automatycznych programów.
                  </PointLink>
                  <PointLink>
                    Usługodawca zastrzega sobie prawo do zmiany jakichkolwiek
                    informacji umieszczonych w Serwisie w wybranym przez
                    Usługodawcę terminie, bez konieczności uprzedniego
                    powiadomienia Usługobiorców korzystających z usług Serwisu.
                  </PointLink>
                  <PointLink>
                    Usługodawca zastrzega sobie prawo do czasowego, całkowitego
                    lub częściowego wyłączenia Serwisu w celu jego ulepszenia,
                    dodawania usług lub przeprowadzania konserwacji, bez
                    wcześniejszego uprzedzania o tym Usługobiorców.
                  </PointLink>
                  <PointLink>
                    Usługodawca zastrzega sobie prawo do wyłączenia Serwisu na
                    stałe, bez wcześniejszego uprzedzania o tym Usługobiorców.
                  </PointLink>
                  <PointLink>
                    Usługodawca zastrzega sobie prawo do dokonania cesji w
                    części lub w całości wszelkich swoich praw i obowiązków
                    związanych z Serwisem, bez zgody i możliwości wyrażania
                    jakichkolwiek sprzeciwów przez Usługobiorców.
                  </PointLink>
                  <PointLink>
                    Obowiązujące oraz poprzednie Regulaminy Serwisu znajduję się
                    na tej podstronie pod aktualnym Regulaminem.
                  </PointLink>
                  <PointLink>
                    We wszelkich sprawach związanych z działalnością Serwisu
                    należy kontaktować się z Usługodawcę korzystając z jednej z
                    poniższych form kontaktu:
                    <RulesPointList>
                      <PointLink>
                        Używając formularza kontaktowego dostępnego w Serwisie
                      </PointLink>
                      <PointLink>
                        Wysyłając wiadomość na adres e-mail: biuro@ratujemydane.pl
                      </PointLink>
                      <PointLink>
                        Poprzez połączenie telefoniczne z numerem: 
                      </PointLink>
                    </RulesPointList>
                    Kontakt przy użyciu wskazanych środków komunikacji wyłącznie
                    w sprawach związanych z prowadzonym Serwisem.
                  </PointLink>
                </RulesPointList>
              </RulesContainer>
            </TextWrapper>
          </Column1>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default DataRules;
