import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const FooterContainer = styled.footer`
    background-color: #101522;
`;

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-left:15%;
    margin-right: auto;
    max-width: 1300px;
    
    @media screen and (max-width: 768px){
        margin: 0 auto 0 auto;
    }
`;

export const FooterLinkContainer = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`;

export const FooterLinkWrapper = styled.div`
    display: flex;
    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin: 16px;
    text-align: left;
    box-sizing: border-box;
    color: #fff;
    margin-right: 1%;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const FooterLinkTitle = styled.h1`
    font-size: 18px;
    margin-bottom: 16px;
`

export const FooterLink = styled(LinkR)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 16px;

    &:hover {
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`;

export const FooterLinkS = styled(LinkS)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 16px;

    &:hover {
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`;

export const FooterP = styled.p`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 16px;
`;

export const SocialMedia = styled.section`
    width: 100%;
    max-width: 1300px;
    justify-content: center;
    align-content: center;
    display: relative;
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`;

export const SocialLogo = styled(LinkR)`
        display: flex;
`;

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;
    font-size: 16px;
    align-items: center;
    text-align:center;

    @media screen and (max-width: 768px) {
        font-size: 13px;
    } 
`;

export const SocialIcon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%;
`;

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
`;

export const ExternalFooterLink = styled.a`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`;

export const CbodFooter = styled.img`
    width: 300px;
    height: 180px;
    display: flex;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
    } 
`;

export const GoogleMap = styled.iframe`
    width: 540px;
    height: 300px; 
    
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        margin-left:0px;
    } 

`