import React from "react";
import {
  AboutContainer,
  AboutH1,
  AboutP,
  Column1,
  Column2,
  Img,
  InfoRow,
  AboutLi
} from "./AboutElements";
import img from "../../images/about.png";


const About = ({ id, alt, header, description, imgStart }) => {
  return (
    <AboutContainer id={id}>
      <InfoRow imgStart={imgStart}>
        <Column1>
          <Img src={img} alt={alt} />
        </Column1>
        <Column2>
          <AboutH1>{header}</AboutH1>
          <AboutP>W dzisiejszych czasach telefony komórkowe i smartfony stały się nieodłącznym elementem naszego życia. Zawierają one wiele cennych informacji, takich jak kontakty, zdjęcia, filmy czy dokumenty, które mogą być niezwykle ważne dla naszej pracy lub życia prywatnego. Niestety, zdarza się, że dane z telefonu mogą zostać utracone w wyniku awarii sprzętu, przypadkowego usunięcia, błędów systemowych lub ataków wirusowych.<br /><br />

W takich sytuacjach warto skorzystać z usług profesjonalnej firmy zajmującej się odzyskiwaniem danych z telefonów. Nasza firma specjalizuje się w odzyskiwaniu danych z telefonów komórkowych i smartfonów różnych marek i modeli. Dysponujemy najnowocześniejszym sprzętem i oprogramowaniem, dzięki czemu możemy odzyskać nawet te dane, które uważano za utracone na zawsze.<br /><br />

Oferujemy kompleksowe usługi odzyskiwania danych, w tym:<ul>

<AboutLi>Odzyskiwanie danych z telefonów uszkodzonych mechanicznie lub elektronicznie</AboutLi>
<AboutLi>Odzyskiwanie danych z telefonów po zalaniu</AboutLi>
<AboutLi>Odzyskiwanie danych po przypadkowym usunięciu lub formatowaniu pamięci telefonu</AboutLi>
<AboutLi>Odzyskiwanie danych z telefonów z zablokowanym systemem operacyjnym lub hasłem</AboutLi></ul><br />
W naszej pracy stosujemy wyłącznie sprawdzone metody odzyskiwania danych oraz gwarantujemy pełną poufność i bezpieczeństwo przetwarzanych informacji. Oferujemy także konkurencyjne ceny oraz szybki czas realizacji usługi, co sprawia, że jesteśmy idealnym wyborem dla osób, które potrzebują szybkiego i skutecznego odzyskania danych z telefonu.</AboutP>
        </Column2>
      </InfoRow>
    </AboutContainer>
  );
};

export default About;
