import React, { useState } from 'react';

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/footer';
import DataRodo from '../components/RulesSection/RodoComponent/DataRodo';
import { articleObjTwo } from '../components/RulesSection/RodoComponent/Data';



const PolitykaPrywatnosci = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/> 
        <DataRodo {...articleObjTwo}/>
        <Footer />
    </>
  );
};

export default PolitykaPrywatnosci;