import {React, useEffect, useState} from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { 
    Nav,
    NavbarContainer,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavLogo
} from './NavbarElements';

import {animateScroll as scroll} from 'react-scroll';
import cbodLogoNavbar from '../../images/CBOD_navbar.png';


const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return(
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        <NavLogo to="/" onClick={toggleHome}> <img src={cbodLogoNavbar} style={{width: '120px', height: '70px'}} alt=""/> </NavLogo>
                        <MobileIcon onClick={toggle}> <FaBars /> </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to='index' smooth={true} duration={700} spy={true} exact='true' offset={-100} activeClass='active'>Strona Główna</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='about' smooth={true} duration={700} spy={true} exact='true' offset={-100} activeClass='active'>O Nas</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='oferta' smooth={true} duration={700} spy={true} exact='true' offset={-100} activeClass='active'>Oferta</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='contact' smooth={true} duration={700} spy={true} exact='true' offset={-100} activeClass='active'>Kontakt</NavLinks>
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}
export default Navbar;