import styled from "styled-components";

export const AboutContainer = styled.div`
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100%;
    position: relative;
    z-index: 2;
    margin-bottom: 30px;

    @media screen and (max-width:768px){
        height: 100%;
    }
    @media screen and (max-width:480px){
        height: 100%;
    }
`;

export const AboutH1 = styled.h1`
    color: #000;
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    margin: 20px;

    @media screen and (max-width:768px){
        font-size: 28px;
    }
    @media screen and (max-width:480px){
        font-size: 24px;
    }
`;

export const AboutP = styled.p`
    margin-top: 24px;
    color: #000;
    font-size: 16px;
    text-align: justify;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 768px) {
       grid-template-areas: ${({ imgStart }) => imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 15px 0;
    grid-area: col1;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const Img = styled.img`
    width: 60%;

    @media screen and (max-width: 768px){
        margin-left: 22%;
        margin-right: auto;
    }
`;

export const AboutLi = styled.li `
    margin-left: 20px;
    margin-right: auto;
    `