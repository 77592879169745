import React from 'react';
import Icon1 from '../../images/services/smartphone.png';
import Icon2 from '../../images/services/ssd.png';
import Icon3 from '../../images/services/hdd.png';

import { ServicesContainter,
         ServicesH1,
         ServicesH2,
         ServicesCard,
        ServicesWrapper,
        ServicesIcon,
        ServicesP,
        ServicesS } from './Services';

const Services = ({id, header}) => {
  return (
    <ServicesContainter id={id}>
            <ServicesH1>{header}</ServicesH1>
        <ServicesWrapper>
            <ServicesS to="smartphone">
            <ServicesCard>
                <ServicesIcon src={Icon1} />
                <ServicesH2>Smartphone</ServicesH2>
                <ServicesP>
                     
                </ServicesP>
            </ServicesCard>
            </ServicesS>
            <ServicesS to="ssd">
            <ServicesCard>
                <ServicesIcon src={Icon2} />
                <ServicesH2>Dyski SSD</ServicesH2>
                <ServicesP>
                   W niedalekiej przyszłości również dyski SSD
                </ServicesP>
            </ServicesCard>
            </ServicesS>
            <ServicesS to="HDD">
            <ServicesCard>
                <ServicesIcon src={Icon3} />
                <ServicesH2>Dyski HDD</ServicesH2>
                <ServicesP>
                W niedalekiej przyszłości również dyski HDD
                </ServicesP>
            </ServicesCard>
            </ServicesS>
        </ServicesWrapper>
    </ServicesContainter>
  );
};

export default Services;