export const articleObjOne = {
    id: 'polityka-prywatnosci',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Polityka prywatności',
    headline: 'Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies.',
    buttonLabel: 'Kliknij aby wrócić',
    imgStart: false,
    alt: 'Polityka Prywatności',
    dark: true,
    primary: true,
    darkText: true
};

