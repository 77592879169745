import React from 'react';
import { FaFacebook } from 'react-icons/fa';
import  Allegro from '../../images/social/allegro.png';
import OLX from '../../images/social/pngegg.png';
import { animateScroll as scroll } from 'react-scroll';
import { 
    FooterContainer,
    FooterWrap,
    FooterLinkContainer,
    FooterLinkTitle,
    FooterLinkItems,
    FooterLink,
    FooterLinkWrapper,
    FooterP,
    SocialMedia,
    SocialIcon,
    SocialLogo,
    SocialIconLink,
    WebsiteRights,
    SocialMediaWrap,
    ExternalFooterLink,
    FooterLinkS,
    CbodFooter,
    GoogleMap} from './FooterElements';

import cbodFooter from '../../images/CBOD_footer.png';

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
  


    return (
    <>
        <FooterContainer>
            <FooterWrap>
                <FooterLinkContainer>
                    <FooterLinkWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Centralne Biuro Odzyskiwania Danych</FooterLinkTitle>
                                <FooterP>Kontakt</FooterP>
                                <ExternalFooterLink href="mailto:biuro@ratujemydane.pl">biuro@ratujemydane.pl</ExternalFooterLink>
                                <ExternalFooterLink href="tel:666349210">666-349-210</ExternalFooterLink> <br />
                                <FooterLinkTitle>O Nas</FooterLinkTitle>
                                <FooterLink to="/rodo">RODO</FooterLink>
                                <FooterLink to="/rules">Regulamin</FooterLink>
                                <FooterLink to="/polityka-prywatnosci">Polityka Prywatności</FooterLink>
                                <FooterLinkS to="contact" smooth={true}>Kontakt</FooterLinkS>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <GoogleMap title='CBODPIN' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9738.231251742987!2d17.2174571!3d52.3965659!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf888822dd0cce90c!2sCentralne%20Biuro%20Odzyskiwania%20Danych!5e0!3m2!1spl!2spl!4v1665598882573!5m2!1spl!2spl" style={{border:'0'}} allowfullscreen={false} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></GoogleMap>
                        </FooterLinkItems>
                    </FooterLinkWrapper>
                </FooterLinkContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}><CbodFooter alt="" src={cbodFooter} /></SocialLogo>
                        <WebsiteRights>ratujemydane.pl © {new Date().getFullYear()} <br />Wszystkie prawa zastrzeone.</WebsiteRights>  
                        <SocialIcon>
                            <SocialIconLink href='' target="_blank" aria-label='Facebook'><FaFacebook /></SocialIconLink>
                            <SocialIconLink href='' target="_blank" aria-label='Allegro' ><img src={Allegro} alt="Allegro" height="32" width="32"/></SocialIconLink>
                            <SocialIconLink href='' target="_blank" aria-label='OLX' ><img src={OLX} alt="Allegro" height="32" width="32"/></SocialIconLink> 
                        </SocialIcon> 
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    </>
  );
};

export default Footer;