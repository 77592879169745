import React, {useState} from 'react';
import {
    MovieContainer,
    MovieBg,
    VideoBg,
    MovieContent,
    MovieH1,
    MovieP,
    MovieBtnWrapper,
    ArrowForward
} from './MovieElements';
import { Button ,Button2 } from '../ButtonElements';
import VideoLoop from '../../videos/movie.mp4';

const MovieSection = () => {
const [hover, setHover] = useState(false);
const [hover2, setHover2] = useState(false);

const onHover = () => {
    setHover(!hover);
    setHover2(!hover2);
}

  return (
    <>
        <MovieContainer id='index'>
            <MovieBg>
                <VideoBg autoPlay loop muted src={VideoLoop} type='video/mp4' />
            </MovieBg>
            <MovieContent>
                <MovieH1>Jesteśmy dostępni przez cały czas !</MovieH1>
                <MovieP></MovieP>
                <MovieP></MovieP>
                <MovieBtnWrapper>
                    <Button2 href='tel:666349210' onMouseEnter={onHover} onMouseLeave={onHover}  primary2='false' dark2='false'>
                        Zadzwon ! <ArrowForward />
                    </Button2> 
                    <Button to='contact' onMouseEnter={onHover} onMouseLeave={onHover} smooth={true} primary='true' dark='true'>
                        Formularz kontaktowy ! <ArrowForward />
                    </Button>
                </MovieBtnWrapper>
            </MovieContent>
        </MovieContainer>
    </>
  );
};

export default MovieSection;