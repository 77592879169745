import React, { useState } from 'react';

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/footer';
import DataPrivacyPolicy from '../components/RulesSection/DataPrivacyComponent/DataPrivacyPolicy';
import { articleObjOne } from '../components/RulesSection/DataPrivacyComponent/Data';



const PolitykaPrywatnosci = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/> 
        <DataPrivacyPolicy {...articleObjOne}/>
        <Footer />
    </>
  );
};

export default PolitykaPrywatnosci;