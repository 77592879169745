import styled from 'styled-components';
import {Link} from 'react-scroll';

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#008000' : '#010606')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#fff' : '#010606')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: 1px solid #000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#fff' : '#008000')};
        text-decoration: none;
        color: #000;
    }
`;


export const Button2 = styled.a`
    border-radius: 50px;
    background: ${({primary2}) => (primary2 ? '#008000' : '#fff')};
    white-space: nowrap;
    padding: ${({big2}) => (big2 ? '14px 48px' : '12px 30px')};
    color: ${({dark2}) => (dark2 ? '#fff' : '#010606')};
    font-size: ${({fontBig2}) => (fontBig2 ? '20px' : '16px')};
    outline: none;
    border: 1px solid #010606;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary2}) => (primary2 ? '#fff' : '#DE1A1A')};
        text-decoration: none;
        color: #000;
    }
`;

export const B3 = styled.button`
    border-radius: 50px;
    background: ${({primary3}) => (primary3 ? '#008000' : '#010606')};
    white-space: nowrap;
    padding: ${({big3}) => (big3 ? '14px 48px' : '12px 30px')};
    color: ${({dark3}) => (dark3 ? '#fff' : '#fff')};
    font-size: ${({fontBig3}) => (fontBig3 ? '20px' : '16px')};
    outline: none;
    border: 1px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary3}) => (primary3 ? '#fff' : '#DE1A1A')};
        text-decoration: none;
        color: #000;
    }
`;