import React, {useState, useRef} from 'react';
import { Column1, Column2, ContactH1, ContactContainer, Img, InfoRow, ContactBox, NameBox, PhoneBox, EmailBox, ResizeBox, DeviceTypeBox, ArrowForward} from './ContactElements';
import {B3} from '../ButtonElements';
import emailjs from 'emailjs-com';
import Reaptcha from 'reaptcha';
import contactimg from '../../images/poczta_ikony.png';



const ContactForm = ({id,img,alt,header, imgStart, width, height}) => {
  
  const [hover3, setHover3] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const onHover = () => {
    setHover3(!hover3);
  }
  
  

  const [formStatus, setFormStatus] = React.useState('Wyślij')

  const verify = () =>{
    captchaRef.current.getResponse().then(res => {
        setCaptchaToken(res)
    })

}   

  function onSubmit(e) {
    e.preventDefault();
        emailjs.sendForm('Marcin_ContactBox',
        'template_2hr2xjj', 
        e.target, 
        'JE9LIqwzX_Hc1nRT8'
        ).then(res=>{console.log(res);
        }).catch(err=>console.log(err)); 
        console.log(setCaptchaToken);
        setFormStatus('Zgłoszenie zostało wysłane');
      
      }
    
  
return (
    <ContactContainer id={id} >
      <InfoRow imgStart={imgStart}>
        <Column1>
          <Img src={contactimg} alt={alt} width={width} height={height}/>
        </Column1>
        <Column2>
          <ContactH1>{header}</ContactH1>
            <ContactBox onSubmit={onSubmit}>
              <NameBox id='name' name='name'  placeholder='Imie i nazwisko' type='text' required></NameBox>
              <PhoneBox id='phone' name='phone' placeholder='Telefon kontaktowy' type='text' required></PhoneBox>
              <EmailBox id='email' name='email' placeholder='E-mail' type='email' required></EmailBox>
                <DeviceTypeBox id="device" name='device' required>
                  <option value="Telefon typu Smartphone">Telefon typu Smartphone</option>
                  <option value="Dysk Magnetyczny (HDD)">Dysk Magnetyczny (HDD)</option>
                  <option value="Dysk Elektroniczny (SSD)">Dysk Elektroniczny (SSD)</option>
                  <option value="Pendrive">Pendrive</option>
                </DeviceTypeBox>
              <ResizeBox id='details' name='details' placeholder='Opis' type='text' required></ResizeBox> 
              <Reaptcha sitekey='6LeuomgiAAAAABMpWqMbiU3gtg11efiPlhPsmEv6' ref={captchaRef} size='invisible' theme='dark' onVerify={verify}/>
              <B3  onMouseEnter={onHover} onMouseLeave={onHover} smooth={true} primary3='false' dark3='false'>
                        {formStatus} <ArrowForward />
            </B3>
            </ContactBox>
        </Column2>
      </InfoRow>
    </ContactContainer>
  );
};

export default ContactForm;