import styled from 'styled-components';

export const PrivacyContainer = styled.div`
    position: grid;
    top: 1.2rem;
    right: 1,5rem;
    background: transparent;
    cursor: pointer;
    outline:none;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff' )};
    `;

export const PrivacyTitle = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 36px;
    text-align: justify;
    font-weight: bold;
    letter-spacing: initial;
    line-height: 1.2;    
    `;

export const PrivacyText = styled.p`
    font-size:12px;
    text-align: justify;
`

export const PointList = styled.ul`
    margin-left: 30px;
`

export const InfoContainer = styled.div`
    color: #FFF;
    background: ${({ lightBg }) => (lightBg ? '#F9F9F9' : '#010606')};
    height: 100%;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`    
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1200px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoRow = styled.div`
    display: flex;
    grid-auto-columns: minmax(auto, 2fr);
    align-items: center;
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 15px 0;
`;


export const TextWrapper = styled.div`
    max-width: 1100px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #black;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    color: #000;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#F7F8FA' : '#010606')};

    @media screen and (max-width: 480px){
        font-size: 24px;
    }
`;

export const Subtitle = styled.p`
    max-width: 1000px;
    margin-bottom: 35px;
    font-size: 12px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff' )};
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

